import { useState, useEffect } from 'react';
import {List, ListItem, ListItemText, Typography, Grid, Paper, Button, TextField} from '@mui/material';
import {ref, onValue, update } from 'firebase/database';
import { auth, realtimeDB } from './firebaseConfig';

export const CompletedJobs = () => {
  const userId = auth.currentUser.uid;
  const [completedJobs, setCompletedJobs] = useState([]);
  const [copiesInput, setCopiesInput] = useState({});

  useEffect(() => {

    const jobsRef = ref(realtimeDB, `jobs/${userId}`);
    const unsubscribe = onValue(jobsRef, (snapshot) => {
      const jobsData = snapshot.val();
      const jobsArray = [];
      for (let jobId in jobsData) {
        if (jobsData[jobId].completed) {
          jobsArray.push({ ...jobsData[jobId], id: jobId });
        }
      }
      console.log(jobsArray);
      setCompletedJobs(jobsArray);
    });

    return () => unsubscribe();
  }, [userId]);

  const requeueJob = async (jobId) => {
    const copies = copiesInput[jobId] || 1;
    console.log(`requeuing job ${jobId} with ${copies} copies`);
    if (!jobId) {
      return;
    }
    await update(ref(realtimeDB, `jobs/${userId}/${jobId}`), {
      "completed": false,
      "copies": Math.min(2, Math.max(1, parseInt(copies, 10))) // Clamp copies between 1 and 2
    });
  };

return (
      <>
        <Grid item xs={12}>
          <Typography variant="h5">Completed Jobs: </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3}>
            <List>
              {completedJobs.map((job) => (
                <ListItem key={job.id}>
                  <ListItemText primary={job.filename} secondary={`Copies: ${job.copies}`} />
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    value={copiesInput[job.id] || ""}
                    onChange={(e) => setCopiesInput(prev => ({ ...prev, [job.id]: e.target.value }))}
                    label="Copies"
                    inputProps={{ min: 1, max: 1000 }}
                    style={{ marginRight: "10px" }}
                  />
                  <Button variant="outlined" color="primary" onClick={() => requeueJob(job.id)}>
                    Requeue
                  </Button>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </>
  );
};






