import React from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { Container, Button, Box } from '@mui/material';
import { auth } from './firebaseConfig';

export const Auth = () => {
  const signIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result.user);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Container>
        <Box
            display="flex"
            height="100vh"
            alignItems="center"
            justifyContent="center"
        >
            <Button variant="contained" color="primary" onClick={signIn}>
                Sign in with Google
            </Button>
        </Box>
    </Container>
  );
};
