import { initializeApp } from "firebase/app";
import { getAuth} from "firebase/auth";
import {getDatabase} from "firebase/database";
import {getStorage} from "firebase/storage";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";


// Initialize Firebase (replace with your config)
const firebaseConfig = {
  apiKey: "AIzaSyBr5SSsqbcUd6Dnb99s1ZxihxCxkrxaTfU",
  authDomain: "printer-website.firebaseapp.com",
  databaseURL: "https://printer-website-default-rtdb.firebaseio.com",
  projectId: "printer-website",
  storageBucket: "printer-website.appspot.com",
  messagingSenderId: "431471495334",
  appId: "1:431471495334:web:02b37c485789f66dfa0c67",
  measurementId: "G-YZC03WQW91"
};

const firebaseApp = initializeApp(firebaseConfig);

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider("6LdvN1soAAAAAKnyC2h1TNGOr-7G6SlDVTRC0CiF"),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

const auth = getAuth(firebaseApp);
const realtimeDB = getDatabase(firebaseApp);
const storage = getStorage(firebaseApp);

export {firebaseApp, appCheck, auth, realtimeDB, storage};