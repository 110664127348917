import React, { useState } from 'react';
import {ref as databaseRef, push, set } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import {Button, TextField, Snackbar, Grid} from '@mui/material';
import {auth, realtimeDB, storage} from './firebaseConfig';

export const UploadJob = () => {
    const input = document.querySelector('input[type="file"]');
  const [file, setFile] = useState(null);
  const [copies, setCopies] = useState(1);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const onFileChange = (e) => {
  const selectedFile = e.target.files[0];
  const fileSizeLimit = 5 * 1024 * 1024; // 5 MB
  if (selectedFile.size > fileSizeLimit) {
    alert('File size exceeds the 10MB limit. Please choose a smaller file.');
    resetFile();
  } else {
    setFile(selectedFile);
  }
  };

  const resetFile = () => {
    setFile(null); // Clear the file state
    input.value = '';
  };


  const onUpload = async () => {
    const userId = auth.currentUser.uid;
    if (!file) {
      setMessage('Please select a file to upload.');
      return;
    }

    // Upload to Firebase Storage
    const fileRef = storageRef(storage, `${userId}/${file.name}`);
    try {
      await getDownloadURL(fileRef);
      setMessage('File already exists. Please choose a different name or file.');
      setLoading(false);
      return;
    } catch (error) {
      // File doesn't exist, so continue with the upload
    }
    await uploadBytes(fileRef, file);
    const fileURL = await getDownloadURL(fileRef);
    setMessage('File uploaded successfully!');
    setLoading(false);

    // Add job to Realtime Database
    const newJob = {
      filename: file.name,
      url: fileURL,
      copies: copies,
      userId: userId,
      completed: false,
      timestamp: Date.now()
    };

    const newJobRef = push(databaseRef(realtimeDB, `jobs/${userId}`));
    await set(newJobRef, newJob);

    resetFile();
  };

  return (
      <>
        <Grid item xs={12}>
          <input type="file" onChange={onFileChange} disabled={loading}/>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Copies"
            type="number"
            value={copies}
            onChange={(e) => setCopies(Math.min(2, Math.max(1, e.target.value)))}
            disabled={loading}
            inputProps={{ min: 1, max: 10 }}
            padding={0}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={onUpload} disabled={loading}>
            Upload
          </Button>
        </Grid>
      <Snackbar
        open={message !== ''}
        autoHideDuration={6000}
        onClose={() => setMessage('')}
        message={message}
      />
      </>
  );
};
