import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import { Auth } from './Auth';
import { UploadJob } from './UploadJob';
import { CompletedJobs } from './CompletedJobs';
import {Container, Grid} from "@mui/material";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, []);

  if (!user) {
    return (
        <Container>
          <Auth />
        </Container>
    );
  }

  return (
    <Grid container spacing={3} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
      {/* Your other components */}
      <UploadJob />
      <CompletedJobs />
    </Grid>
  );
}

export default App;
